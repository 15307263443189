import request from '../utils/axios';

// 线上
const originApi = 'https://sem.rlcmarketltd.net/api/proxy';

// index--昨收报价
export function quote(data) {
  return request({
    url:'https://sem.rlclead.com/api/quote/v1/QF1002',
    method: 'POST',
    data
  })
}
// index--行情
export function quoteTime(data) {
  return request({
    url:'https://sem.rlclead.com/api/quote/v1/QF1001',
    method: 'POST',
    data
  })
}
//banner图配置
export function swiperBanner(data){
  return request({
    url: originApi + '/v4/PA159',
    method: 'POST',
    data
  })
}
// export function swiperBanner(data){
//   return request({
//     url: originApi + '/common/brandLineAndImageList',
//     method: 'POST',
//     data
//   })
// }
//图片验证码
export function verifyImgCode(data){
  return request({
    url: originApi + '/v4/U001',
    method: 'POST',
    data
  })
}
//快捷注册
export function quickRegister(data){
  return request({
    url: originApi + '/v4/U101',
    method: 'POST',
    data
  })
}
//pm登录
export function pmLogin(data){
  return request({
    url: originApi + '/v4/U103',
    method: 'POST',
    data
  })
}
// 获取live800
export function getLive800(params) {
  return request({
    url: originApi + '/v4/ACV100',
    method: 'get',
    params
  })
}
