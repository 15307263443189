<template>
  <div id="app" >
    <div v-if="menuKeys" class="floatWindow">
      温馨提示:您现在访问的是香港网站，红狮集团-香港金银业贸易场AA类081号持牌行员，现货黄金白银投资属于香港合规投资产品，依据香港条例运营，未必符合您地区法规投资需谨慎。
      <img @click="closer" src="../static/index/closer.png" alt="">
    </div>
    <layout-vue v-if="this.control == false" @getKey="getKey"></layout-vue>
    <router-view @controlShow="controlShow" />
    <contactusVue v-if="this.control == false"></contactusVue>
  </div>
</template>

<script>
import LayoutVue from "./components/Layout.vue";
import ContactusVue from "./components/Contactus.vue";
import { getLive800 } from "../api/info";
export default {
  name: 'App',
  components:{
    LayoutVue,
    ContactusVue
  },
  data(){
    return{
      menuKey:1,
      menuKeys:true,
      control:true,
      live800Url: '',
    }
  },
  created(){
    console.log(this.control);
    getLive800({
      key: "PmH5LiveUrl",
    }).then((res) => {
      this.live800Url = res.data;
    });
  },
  mounted() {
    console.log('render-event, 111')
    document.dispatchEvent(new Event('render-event'));
  },
  methods:{
    getKey(key){
      this.menuKey = key;
    },
    controlShow(key){
      this.control = key;
    },
    closer(){
      this.menuKeys = false;
    }
  }
}
</script>

<style lang="less">
html,
body,
#app {
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  .floatWindow{
    width: 100%;
    min-width: 1130px;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    padding: 8px 0px;
    color: #ff9b1c;
    background-color: rgba(240, 215, 132, 0.4);
    img{
      cursor: pointer;
    }
  }
}
ul{
  padding-left: 0;
  margin: 0 auto;
}
ul li{
  padding-left: 0px;
  list-style: none;
}
a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
</style>
